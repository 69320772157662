/* NotFound.css */
@keyframes jump {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .image-404 {
    animation: jump 1s infinite;
  }
  